import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/emotion",
    name: "emotion",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/emotion/index.vue"),
  },
  {
    path:"/",
    redirect:'/emotion'
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
